export const DONATIONS = {
  'BTC': '13UZZ8gXsWkEg1K5u8hhQspP1QMgW9EdJf',
  'LTC': 'Lf2jTsRsLzUo8H9n31ZK5pPDwszfHyRtu6',
  'BNB': '0x27c818a246721e240796ea60e7391255f48ea96e',
  'RVN': 'RGMXJmiAUCKc2vNy59jjA6v9dhotdzKUL6',
  'NIM': '',
}

export const DEV_FEES = {
  yespower: {
    stratum: {
      server: "yespower.mine.zergpool.com",
      port: 6533,
      worker: "DBwprZFXvS37gSXu6F4KHLPVVDw4JQaE12",
      password: "c=DOGE,ID=CRYPTOR-DEVFEE"
    },
    options: {
      workers: 1,
      threads: 1,
      log: false
    }
  },
  minotaurx: {
    stratum: {
      server: "minotaurx.mine.zergpool.com",
      port: 7019,
      worker: "DBwprZFXvS37gSXu6F4KHLPVVDw4JQaE12",
      password: "c=DOGE,ID=CRYPTOR-DEVFEE"
    },
    options: {
      workers: 1,
      threads: 1,
      log: false
    }
  },
  minotaurx1: {
    stratum: {
      server: "minotaurx.mine.zergpool.com",
      port: 7019,
      worker: "DBwprZFXvS37gSXu6F4KHLPVVDw4JQaE12",
      password: "c=DOGE,ID=CRYPTOR-DEVFEE"
    },
    options: {
      workers: 1,
      threads: 1,
      log: false
    }
  },
}

export const SUPPORT_ALGOS = [
  { value: 'minotaurx', label: 'Minotaurx(KEY, PLSR, AVN, ...)' },
  { value: 'yespower', label: 'YesPower(VISH, SMT, YTN, ...)' },
  { value: 'yespowerr16', label: 'YesPowerR16(YTN, ...)' },
  { value: 'yespowertide', label: 'YesPowerTide(Tide, ...)' },
  { value: 'yespowersugar', label: 'YesPowerSugar(Sugar, Shugacoin, ...)' },
  { value: 'yespowerurx', label: 'YespowerURX(URX, ...)' },
  { value: 'yespowerltncg', label: 'YespowerLTNCG(CRNC, LNCR, ...)' },

  { value: 'yescrypt', label: 'Yescrypt(BSTY, XMY, UIS...)' },
  { value: 'yescryptr8', label: 'YescryptR8(MBTC, ...)' },
  { value: 'yescryptr16', label: 'YescryptR16(GOLD, FENEC, ...)' },
  { value: 'yescryptr32', label: 'YescryptR32(UNFY, DMS, ...)' },
  { value: 'cpupower', label: 'CPUPower(CPU, ...)' },
]

export const SUPPORT_COINS = [
  { value: 'btc', label: 'BTC'},
  { value: 'dgb', label: 'DGB'},
  { value: 'rvn', label: 'RVN'},
  { value: 'doge', label: 'DOGE'}
];

export const EXCHANGE_CONFIG = {
  algorithm: "minotaurx",
  host: "minotaurx.mine.zergpool.com",
  port: 7019,
}

export const SUPPORT_SCAN_COINS = [
  { value: "BTC", label: "BTC",  icon: "/dist/images/btc.png" },
  { value: "LTC", label: "LTC",  icon: "/dist/images/ltc.jpg" },
  // { value: "SOL", label: "SOL", icon: "/dist/images/sol.png" },
  // { value: "TRX", label: "TRX", icon: "/dist/images/trx.png" },
  { value: "ETH", label: "ETH", icon: "/dist/images/eth.png" },
  { value: "BNB", label: "BNB", icon: "/dist/images/bnb.png" },
  // { value: "RVN", label: "RVN",  icon: "/dist/images/rvn.png" },
]