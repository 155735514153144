import { createApp } from 'vue'
import Antd from 'ant-design-vue';
import App from './App.vue'
import 'ant-design-vue/dist/reset.css';
import { createWebHistory, createRouter } from 'vue-router';
import Home from './pages/Home.vue';
import Scan from './pages/Scan.vue';

const routes = [
  { path: '/', component: Home },
  { path: '/scan', component: Scan },
]
const router = createRouter({
  history: createWebHistory(),
  routes,
})

// Create app
createApp(App)
  .use(Antd)
  .use(router)
  .mount('#app')
